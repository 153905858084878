<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 class="card-title">
          Create New Location
        </h4>
      </div>
      <div class="card-body row">
        <!-- Create new location markup -->
        <div class="form-group col-6">
          <label>Name</label>
          <fg-input  type="text"
                     name="name"
                     v-validate="modelValidations.name"
                     :error="getError('name')"
                     v-model="model.name">
          </fg-input>
        </div>
        <div class="form-group col-6">
          <label>Address Line</label>
          <fg-input  type="text"
                     name="address_line"
                     v-validate="modelValidations.address_line"
                     :error="getError('address_line')"
                     v-model="model.address_line">
          </fg-input>
        </div>
        <div class="form-group col-6">
          <label>City</label>
          <fg-input type="text"
                    name="city"
                    v-validate="modelValidations.city"
                    :error="getError('city')"
                    v-model="model.city">
          </fg-input>
        </div>
        <div class="form-group col-6">
          <label>State</label>
          <fg-input type="text"
                    name="state"
                    v-validate="modelValidations.state"
                    :error="getError('state')"
                    v-model="model.state">
          </fg-input>
        </div>
        <div class="form-group col-6">
          <label>Country</label>
          <fg-input type="text"
                    name="country"
                    v-validate="modelValidations.country"
                    :error="getError('country')"
                    v-model="model.country">
          </fg-input>
        </div>
        <div class="form-group col-6">
          <label>Pincode</label>
          <fg-input type="number"
                    name="pincode"
                    v-validate="modelValidations.pincode"
                    :error="getError('pincode')"
                    v-model="model.pincode"></fg-input>
        </div>
        <div class="form-group col-6">
          <label>Email address</label>
          <fg-input  type="email"
                     name="email"
                     v-validate="modelValidations.email"
                     :error="getError('email')"
                     v-model="model.email">
          </fg-input>
        </div>
        <div class="form-group col-6">
          <label>Phone</label>
          <fg-input type="number"
                    name="phone"
                    v-validate="modelValidations.phone"
                    :error="getError('phone')"
                    v-model="model.phone">
          </fg-input>
        </div>
        <div class="form-group col-6">
          <label>GSTIN</label>
          <fg-input type="text"
                    name="gstin"
                    v-validate="modelValidations.gstin"
                    :error="getError('gstin')"
                    v-model="model.gstin">
          </fg-input>
        </div>
        <!-- end of new location creation -->
      </div>
      <div class="card-footer text-right">
        <p-button type="info" @click.prevent="validate">Create</p-button>
      </div>
    </form>

  </div>
</template>
<script>
  export default {
    data () {
      return {
        model: {
          name: '',
          address_line: '',
          city: '',
          state: '',
          country: '',
          pincode: '',
          email: '',
          phone: '',
          gstin: ''
        },
        modelValidations: {
          name: {
            required: true
          },
          address_line: {
            required: true
          },
          city: {
            required: true
          },
          state: {
            required: true
          },
          country:  {
            required: true
          },
          email: {
            required: true,
            email: true
          },
          pincode: {
            required: true,
            max: 6,
            min: 6
          },
          phone: {
            required: true,
            min: 10,
            max: 10
          },
          gstin:  {
            required: true
          },
        }
      }
    },
    methods: {
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.registerForm, isValid)
          if(isValid)
          this.$store.dispatch('createLocation', this.model);
        })
      }
    }
  }
</script>
<style>
</style>
